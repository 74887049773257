.copyright{
    height: 60px;
    background-color: var(--lightPurple);
    color:var(--white);
    padding: 20px 10%;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
}
@media(max-width:575px){  
    .copyright{
      height: 40px;
      padding: 12px 20px;
    }
}

.homeQuotes,.homeWhatsapp{
  position: fixed;
  bottom: 10px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  z-index: 1001;
}
.homeQuotesImg,.homeWhatsappImg{
  width: 50px;
}
.homeQuotes:hover,.homeQuotes:focus,.homeWhatsapp:hover,.homeWhatsapp:focus{
  opacity: 1;
}
.homeWhatsapp{
  bottom: 70px;
}
.copyrightModal.modal{
  background-color: transparent;
  padding: 0;
  max-width: 450px;
  max-height: 650px;
  min-height: 450px;
  right: 20px;
  margin-left: 20px;
  position: fixed;
  left: auto;
  bottom: 70px;
  top: auto;
}
.copyrightModal .modal-body{
  padding: 35px;
}
@media(max-width:1199px){
    .homeQuotesImg,.homeWhatsappImg{
        width: 40px;
    }
    .homeWhatsapp {
        bottom: 60px;
    }
    .copyrightModal.modal{
        bottom: 70px;
    }
}
@media(max-width:1024px){
  .copyrightModal.modal{
    max-height: 500px;
  }
}
@media(max-width:500px){
    .copyrightModal.modal{
        width: 90%;
    }
    .copyrightModal .modal-body{
        padding: 35px 10px;
    }
}