.singlePageBanner{
    min-height: 450px;
    padding: 120px 10%;
    text-align: center;
    background: url('../../../public/img/b.png') bottom no-repeat,url('../../../public/img/singlePageBanner.jpg') left top no-repeat;
    background-size: 100%,cover;
    background-color: var(--white);
    position: relative;
    overflow: hidden;
    color: var(--white);
}
.singlePageBanner .page-heading{
    font-size: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 1000px;
    margin: 0 auto;
}
.singlePageBanner .breadcrumbs{
    font-size: 22px;
}
.singlePageBanner .breadcrumbs span,.singlePageBanner .breadcrumbs a{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
}
@media(max-width:1380px){
    .singlePageBanner .page-heading{
        font-size: 45px;
    }
}
@media(max-width:1199px){
    .singlePageBanner{
        min-height: 350px;
        padding: 100px 10%;
    }
    .singlePageBanner .page-heading{
        font-size: 35px;
    }
    .singlePageBanner .breadcrumbs{
        font-size: 18px;
    }
}
@media(max-width:767px){
    .singlePageBanner{
        min-height: 250px;
        padding: 70px 10%;
    }
    .singlePageBanner .page-heading{
        font-size: 30px;
    }
    .singlePageBanner .breadcrumbs{
        font-size: 16px;
    }
}