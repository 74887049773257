.services{
    margin: 50px 0 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.services .services-columns{
    width: 30%;
    max-width: 400px;
    margin-bottom: 50px;
    padding: 30px;
    transition: all .5s;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    text-align: center;
    min-height: 300px;
}
.services .services-columns:hover{
    box-shadow: 0 0 10px 4px rgb(31 66 135 / 10%);
}
.services .services-columns::after, .services .services-columns::before{
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    opacity: 0;
    transition: all .5s;
    -webkit-transform: scale(.5);
    transform: scale(.5);
}
.services .services-columns::after{
    background-image: url('../../../public/img/servicesBg2.png');
    height: 290px;
    bottom: -40px;
    right: -30px;
    width: 250px;
}
.services .services-columns::before{
    background-image: url('../../../public/img/servicesBg1.png');
    height: 100%;
    top: -30px;
    left: -30px;
    width: 150%;
}
.services .services-columns.active::after, .services .services-columns.active::before, .services .services-columns:hover::after, .services .services-columns:hover::before{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.services .services-columns .icon-box{
    border-radius: 50px;
    width: 90px;
    height: 90px;
    margin: 24px auto 45px;
    position: relative;
    z-index: 1;
    padding: 18px;
}
.services .services-columns .icon-box::before{
    content: "";
    position: absolute;
    right: -20px;
    top: -20px;
    height: 133px;
    width: 82px;
    background-position: 100%;
    z-index: -1;
    transition: all .5s;
}
.services .services-columns .icon-box.bg-yellow{
    background-color:#ffa808;
}
.services .services-columns .icon-box.bg-red{
    background-color: #ff586e;
}
.services .services-columns .icon-box.bg-green{
    background-color : #029e76;
}
.services .services-columns .icon-box.bg-blue{
    background-color:#00aeff;
}
.services .services-columns .icon-box.bg-orange{
    background-color: #ff8853;
}
.services .services-columns .icon-box.bg-maroon{
    background-color : #9e0168;
}
.services .services-columns .icon-box.bg-yellow::before {
    background-image: url('../../../public/img/icon1.png');
}
.services .services-columns .icon-box.bg-red::before{
    background-image: url('../../../public/img/icon2.png');
}
.services .services-columns .icon-box.bg-green::before{
    background-image: url('../../../public/img/icon3.png');
}
.services .services-columns .icon-box.bg-blue::before{
    background-image: url('../../../public/img/icon4.png');
}
.services .services-columns .icon-box.bg-orange::before{
    background-image: url('../../../public/img/icon5.png');
}
.services .services-columns .icon-box.bg-maroon::before{
    background-image: url('../../../public/img/icon6.png');
}
.services-columns .icon-box img{
    height: 50px;
}
.services .services-columns:hover .icon-box img,.services .services-columns.active .icon-box img{
    --webkit-animation: toTopFromBottom .8s forwards;
    animation: toTopFromBottom .8s forwards;
}
.services .services-columns.active>.icon-box::before, .services .services-columns:hover>.icon-box::before {
    right: 30px;
}
.service-title{
    font-size: 24px;
    color: var(--navy);
    font-weight: 700;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.services .services-columns .content{
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
@media(max-width:1400px){
    .services .services-columns::before{
        top: -15px;
        left: -15px;
    } 
    .services .services-columns::after{
        bottom: -20px;
        right: -10px;
    } 
}
@media(max-width:1199px){
    .service-title{
        font-size: 20px;
    }
}
@media(max-width:991px){
    .services .services-columns{
        width: 45%;
    }
}
@media(max-width:767px){
    .services .services-columns{
        box-shadow: 0 0 10px 4px rgb(31 66 135 / 5%);
    }
}
@media(max-width:575px){
    .services .services-columns{
        width: 90%;
    }
}