.contact-section{
    padding: 50px 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.contact-section .contact-columns1,.contact-section .contact-columns2{
    width: 48%;
}
.contactImg{
    display: block;
    margin: auto;
    width: 100%;
    animation: move1 5s infinite;
}
@media(max-width:991px){
    .contact-section .contact-columns1,.contact-section .contact-columns2{
        width: 100%;
        text-align: center;
    }
    .contactImg{
        max-width: 600px;
    }
}