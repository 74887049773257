.topHeader{
    height: 30px;
    z-index: 1000;
    background: var(--purple);
    color:var(--white);
    padding: 5px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: 0;
}
.topHeader .contactBox,.topHeader .socialLinks{
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.topHeader .links{
    cursor:pointer;
    margin-right: 20px;
}
.topHeader .contactBox .icons{
    height: 13px;
    margin-right: 8px;
}
.topHeader .socialLinks .icons{
    width: 23px;
    height: 23px;
    padding: 4px;
    border-radius: 4px;
    margin-left: 5px;
}
.topHeader .socialLinks .icons:hover{
    background-color: var(--blackOpacity);
}
@media(max-width:575px){
    .topHeader{
        height: 30px;
        padding: 5px 20px;
        justify-content: center;
    }
    .topHeader .socialLinks{
        display: none;
    }
}