.mini-title{
    color: var(--navy);
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.content,ul li{
    font-size: 18px;
}
@media(max-width:767px){
    .mini-title{
        font-size: 20px;
    }
    .content,ul li{
        font-size: 16px;
    }
}
@media(max-width:575px){
    .mini-title{
        font-size: 18px;
    }
    .content,ul li{
        font-size: 14px;
    }
}