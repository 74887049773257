.contact-flex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.contact-flex .contact-box{
    width: 24%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    box-shadow: 7px 5px 30px rgb(72 73 121 / 15%);
    transition: .4s;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
}
.contact-flex .contact-box:hover{
    box-shadow: 0 0 30px 0 rgb(67 67 67 / 15%);
    transform: translateY(-5px);
}
.contact-flex .contact-box .icon{
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border: dotted 2px var(--black);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
}
.contact-flex .contact-box .icon img{
    height: 20px;
}
.contact-flex .contact-box:hover .icon{
    border-color: var(--purple);
    background-color: var(--purple);
}
.contact-flex .contact-box:hover .icon .phone{
    content: url('../../../public/img/phone.png');
}
.contact-flex .contact-box:hover .icon .email{
    content: url('../../../public/img/email.png');
}
.contact-flex .contact-box:hover .icon .home{
    content: url('../../../public/img/address.png');
}
.contact-flex .contact-box:hover .icon .whatsapp{
    content: url('../../../public/img/whatsapp-white.png');
}

.contact-flex .contact-box .contact-content h4{
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 600;
    color: var(--black);
}
.contact-flex .contact-box .contact-content .links{
    font-size: 16px;
    display: block;
    color: var(--gray);
    margin-bottom: 5px;
}
.contact-flex .contact-box .contact-content .links:last-child{
    margin-bottom: 0;
}
@media(max-width:1199px){
    .contact-flex .contact-box{
        width: 49%;
    }
    .contact-flex .contact-box:first-child,.contact-flex .contact-box:nth-child(2){
        margin-bottom: 20px;
    }
}
@media(max-width:575px){
    .contact-flex .contact-box{
        width: 100%;
    }
    .contact-flex .contact-box:nth-child(3){
        margin-bottom: 20px;
    }
}