.highwaySlider {
    width: 100%;
}
.highwaySlider .highwayBarrier {
    overflow: hidden;
    position: relative;
    background: #fff;
    padding: 10px;
    /* box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.3); */
}
/* .highwaySlider .highwayBarrier::before,
.highwaySlider .highwayBarrier::after {
  content: " ";
  position: absolute;
  z-index: 9;
  width: 180px;
  height: 100%;
} */
.highwaySlider .highwayBarrier::before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.highwaySlider .highwayBarrier::after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.highwaySlider .highwayBarrier .highwayLane {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    width: calc(180px * 10);
    margin-left: 0;
    -webkit-animation: translateinfinite 10s linear infinite;
          animation: translateinfinite 10s linear infinite;
}
.highwaySlider .highwayBarrier .highwayLane .highwayCar {
    background: #fff;
    min-width:180px;    
}
.highwaySlider .highwayLane .highwayCar .clientImage img{
    max-width: 60%;
    max-height: 130px;
}