.about-page{
    text-align: center;
}
.about-page .about-flex{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0 0;
}
.about-page .about-flex .about-column{
    width: 20%;
}
.about-page .about-flex .about-column:nth-child(2){
    margin-left: 5%;
    margin-right: 5%;
}
.about-page .about-flex .about-column .heading{
    font-size: 24px;
    font-weight: 700;
    color: var(--black);
    margin-bottom: 15px;
}
.about-page .about-flex .about-column .icon-box{
    height: 100px;
    width: 100px;
    border-radius: 50px;
    background: transparent;
    padding: 30px;
    display: block;
    margin: 0 auto 20px;
    box-shadow: 0 0 10px 4px rgb(31 66 135 / 10%);
}
.about-page .about-flex .about-column:hover .icon-box{
    background-image: linear-gradient(#896eff 0,#5f3bff 70%);
}
.about-page .about-flex .about-column:hover .icon-box img{
    --webkit-animation: toTopFromBottom .8s forwards;
    animation: toTopFromBottom .8s forwards;
}
.about-page .about-flex .about-column:hover .icon-box img.designing{
    content : url('../../../public/img/illustration.png');
}
.about-page .about-flex .about-column:hover .icon-box img.development{
    content : url('../../../public/img/graphic-design.png');
}
.about-page .about-flex .about-column:hover .icon-box img.launching{
    content : url('../../../public/img/startup.png');
}
@media (max-width:1380px){
    .about-page .about-flex .about-column{
        width: 25%;
    }
}
@media (max-width:1199px){
    .about-page .about-flex .about-column{
        width: 30%;
    }
    .about-page .about-flex .about-column:nth-child(2){
        margin-left: 3%;
        margin-right: 3%;
    }
}
@media (max-width:991px){
    .about-page .about-flex .about-column .heading{
        font-size: 22px;
    }
}
@media (max-width:575px){
    .about-page .about-flex .about-column{
        width: 100%;
    }
    .about-page .about-flex .about-column .heading{
        font-size: 20px;
    }
    .about-page .about-flex .about-column:nth-child(2){
        margin-left: 0;
        margin-right: 0;
    }
}
.about-flex-2{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
    align-items: center;
}
.about-column-2{
    width: 48%;
}
@media (max-width:1199px){
    .about-flex-2{
        flex-direction: column-reverse;
        text-align: center;
    }
    .about-column-2{
        width: 100%;
    }
}