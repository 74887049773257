.comming-soon{
    max-width: 900px;
    display: block;
    margin: auto;
}
.blogsPage{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.blogsPage .title{
    margin-top: 20px;
    margin-bottom: 5px;
}
.blogsPage .dated{
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 600;
}
.blogsColumn1{
    width: 72%;
    max-width: calc(100% - 300px);
}
.blogsColumn1 .card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.blogsColumn1 .blog-card{
    width: 32%;
}
.blogsColumn2{
    width: 25%;
    min-width: 280px;
}
.recent-box{
    box-shadow: 0 0 10px rgb(0 5 39 / 10%);
    padding: 10px;
}
.blogsPage .heading{
    font-size: 24px;
    margin: 0 0 20px;
    color: var(--black);
}
.blogsColumn2 .recent-blogs{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    box-shadow: 0 0 10px rgb(0 5 39 / 10%);
    margin-bottom: 10px;
}
.blogsColumn2 .recent-blogs:last-child{
    margin-bottom: 0;
}
.blogsColumn2 .recent-blogs .recent-column1{
    width: 109px;
}
.blogsColumn2 .recent-blogs .recent-column2{
    width: calc(100% - 112px);
}
.blogsColumn2 .recent-blogs .blog-title{
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
}
.blogsColumn2 .recent-blogs .blog-content{
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin-bottom: 0;
}
.recent-column1 .blog-img{
    height: 55px;
}
@media(max-width:1199px){
    .blogsColumn1 .blog-card{
        width: 48%;
    }
}
@media(max-width:991px){
    .blogsPage .heading{
        font-size: 22px;
    }
    .blogsColumn2{
        width: 100%;
        min-width: auto;
        box-shadow: 0 0 10px rgb(0 5 39 / 10%);
    }
    .blogsColumn1{
        max-width: 100%;
        width: 100%;
        margin-bottom: 10px;
    }
}
@media(max-width:767px){
    .blogsPage .heading{
        font-size: 20px;
    }
}
@media(max-width:575px){
    .blogsColumn1 .blog-card{
        width: 100%;
    }
}