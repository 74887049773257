/* hero section  */
.hero-section{
    background: url('../../../public/img/herobg2.png') left 100px bottom 50px no-repeat, url('../../../public/img/herobg3.png') right 100px top 100px no-repeat, url('../../../public/img/herobg.png') bottom left no-repeat;
    background-size: auto 500px, auto 400px, cover;
    min-height: 700px;
    position: relative;
    padding: 150px 10% 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.hero-section .hero-column{
    width: 48%;
}
.hero-section .hero-column .tagline{
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--purple);
}
.hero-section .hero-column .heading{
    font-weight: 700;
    color: var(--navy);
    font-size: 55px;
    margin-bottom: 30px;
}
.hero-section .hero-column .heading .text-primary {
    color: var(--purple);
}
.hero-section .hero-column  .content{
    font-size: 16px;
    margin-bottom: 30px;
}
@media(max-width:1600px){
    .hero-section .hero-column .heading{
        font-size: 50px;
    }
}
@media(max-width:1440px){
    .hero-section .hero-column .heading{
        font-size: 45px;
    }
}
@media(max-width:1380px){
    .hero-section{
        min-height: 600px;
        padding: 100px 10% 50px;
    }
}   
@media(max-width:1199px){
    .hero-section .hero-column .heading{
        font-size: 40px;
    }
    .hero-section{
        min-height: 500px;
        padding: 20px 10%;
    }
}
@media(max-width:991px){ 
    .hero-section .hero-column .heading{
        font-size: 35px;
    }   
    .hero-section .hero-column{
        width: 100%;
    }
    .hero-section{
        padding-top: 80px;
        text-align: center;
    }
    .hero-section .hero-column .tagline{
        margin-bottom: 10px;
    }
    .hero-section .hero-column .content{
        margin-bottom: 20px;
        font-size: 14px;
    }
    .hero-section .connect-btn{
        width: 200px;
        margin: 0 auto 30px;
    }
}
@media(max-width:767px){
    .hero-section .hero-column .heading {
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 15px;
    }
}

/* features, about */
.features-section,.about-section{
    background-color: var(--white);
    padding: 50px 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.features-section .features-columns1,.about-section .about-columns2{
    width: 58%;
}
.features-section .features-columns2,.about-section .about-columns1{
    width: 38%;
}
.feature-card-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.feature-card-box .feature-card{
    width: 48%;
    text-align: left;
}
.feature-card-box .feature-card .card-box{
    box-shadow: 0 0 30px rgb(56 46 46 / 10%);
    transition: .5s;
    padding: 40px 30px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
}
.feature-card-box .feature-card .card-box.card-1{
    background: #f1eff8;
    border: 1px solid #f1eff8;
    margin-bottom: 30px;
}
.feature-card-box .feature-card .card-box.card-2{
    background: #fbe6d4;
    border: 1px solid #fbe6d4;
    margin-top: 40px;
    margin-bottom: 30px;
}
.feature-card-box .feature-card .card-box.card-3{
    background: #f0fffc;
    border: 1px solid #f0fffc;
    margin-top: -40px;
    margin-bottom: 0;
}
.feature-card-box .feature-card .card-box.card-4{
    background: #f8e1eb;
    border: 1px solid #f8e1eb;
    margin-top: 0;
    margin-bottom: 0;
}
.feature-card-box .feature-card:hover .card-box {
    background-color: transparent;
    border: 1px dotted var(--purple);
}
.feature-card-box .feature-card .card-box .icon{
    width: 64px;
    border-radius: 50%;
    padding: 10px;
    height: 64px;
    text-align: center;
    margin-bottom: 20px;
}
.feature-card-box .feature-card .card-box .icon img{
    height:40px;
}
.feature-card-box .feature-card .card-box.card-1 .icon{
    border: dotted 2px #5d43bc;
}
.feature-card-box .feature-card .card-box.card-2 .icon{
    border: dotted 2px #f1760c;
}
.feature-card-box .feature-card .card-box.card-3 .icon{
    border: dotted 2px #19b192;
}
.feature-card-box .feature-card .card-box.card-4 .icon{
    border: dotted 2px #f0116f;
}
.feature-card-box .feature-card .card-box .mini-title{
    font-size: 20px;
    color: var(--black);
    margin-bottom: 10px;
}
.feature-card-box .feature-card .card-box .mini-content{
    font-size: 14px;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.features-section .features-animation .shape-img1 {
    position: absolute;
    right: 25%;
    top: 20%;    
    height: auto;
    -webkit-animation: animationFramesOne 20s linear infinite;
    animation: animationFramesOne 20s linear infinite;
    width: 12px;
}
.features-section .features-animation .shape-img2 {
    position: absolute;
    right: 10%;
    top: 10%;
    width: 200px;
    height: auto;
    -webkit-animation: moveLeftBounce 3s linear infinite;
    animation: moveLeftBounce 3s linear infinite;
}
.aboutImg{
    display: block;
    margin: auto;
    width: 80%;
    animation: move1 5s infinite;
}
@media(max-width:1380px){
    .features-section .features-columns1,.about-section .about-columns2,
    .features-section .features-columns2,.about-section .about-columns1{
        width: 48%;
    }
    .aboutImg{
        width: 100%;
        max-width: 600px;
    }
}
@media(max-width:991px){
    .features-section{
        flex-direction: column-reverse;
    }
    .features-section .features-columns1,.about-section .about-columns2,
    .features-section .features-columns2,.about-section .about-columns1{
        width: 100%;
        text-align: center;
    }
    .features-section .features-columns1,.about-section .about-columns2 .connect-btn,
    .features-section .features-columns2,.about-section .about-columns1 .connect-btn{
        justify-content: center;
    }
}
@media(max-width:575px){
    .feature-card-box .feature-card{
        width: 100%;
    }
    .feature-card-box .feature-card .card-box.card-2{
        margin-top: 0;
    }
    .feature-card-box .feature-card .card-box.card-3{
        margin-top: 0;
        margin-bottom: 30px;
    }
    .features-section .features-animation{
        display: none;
    }
}
/* portfolio-section, services-section */
.portfolio-section,.services-section{
    padding: 50px 10%;
    background-image: url('../../../public/img/bg.png');
    background-size: cover;
    background-position: left top;
    text-align: center;
}
.portfolio-section .connect-btn, .services-section .connect-btn{
    justify-content: center;
    width: 160px;
    margin: auto;
}
.portfolio-flex{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}
.portfolio-flex .galleryItem{
    width: 31%;
    height: 450px;
}
.portfolio-flex .galleryIcons{
    margin-top: 180px;
}
@media(max-width:991px){
    .portfolio-flex .galleryItem{
        width: 48%;
    }
}
@media(max-width:767px){
    .portfolio-flex .galleryItem{
        width: 100%;
        height: 300px;
    }
    .portfolio-flex .galleryIcons{
        margin-top: 120px;
    }
}
/* happy-client-section */
.happy-client-section,.testimonials{
    padding: 50px 10%;
    background-color: var(--lightPurple);
    background-image: url('../../../public/img/bg3.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.happy-client-section .happy-client-column{
    width: 23%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 4px rgb(31 66 135 / 10%);
    padding: 30px;
    transition: all .5s;
}
.happy-client-section .happy-client-column .head{
    font-size: 24px;
    color: var(--navy);
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.happy-client-section .happy-client-column .count{
    color: var(--purple);
    font-size: 35px;
    margin-bottom: 0;
    font-weight: 700;
}
.happy-client-section .happy-client-column .icons{
    width: 90px;
    margin-bottom: 10px;
}
.happy-client-section .happy-client-column:first-child{
    margin-bottom: 30px;
}
.happy-client-section .happy-client-column:nth-child(2){
    margin-top: 30px;
}
.happy-client-section .happy-client-column:nth-child(3){
    margin-bottom: 30px;
}
.happy-client-section .happy-client-column:nth-child(4){
    margin-top: 30px;
    margin-bottom: 0;
}
.happy-client-section .happy-client-column:hover{
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
}
@media(max-width:1199px){
    .happy-client-section .happy-client-column{
        width: 48%;
    }
}
@media(max-width:575px){
    .happy-client-section .happy-client-column{
        width: 100%;
    }
    .happy-client-section .happy-client-column:first-child{
        margin-bottom: 20px;
    }
    .happy-client-section .happy-client-column:nth-child(2){
        margin-top: 0;
        margin-bottom: 20px;
    }
    .happy-client-section .happy-client-column:nth-child(3){
        margin-bottom: 20px;
    }
    .happy-client-section .happy-client-column:nth-child(4){
        margin-top: 20px;
    }
}
.testimonials{
    display: block;
}
.testimonials li{
    background: transparent;
    padding-left: 0;
}
.testimonials .testimonial-groups .avtar{
    display: none;
}
.testimonials .control-dots,.testimonials .control-arrow,.testimonials .carousel-status{
    display: none;
}
.testimonials .testimonial-groups{
    width: 100%;
    max-width: 900px;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 50px 70px;
    background-image: url('../../../public/img/quotation.png');
    background-repeat: no-repeat;
    background-position:  right 30px top 30px;
    background-size: 70px;
}
.testimonials .testimonial-groups .person-name{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
    color: var(--black);
}
.testimonials .testimonial-groups .designation{
    font-size: 15px;
    color: var(--purple);
    margin-bottom: 20px;
    font-weight: 500;
    text-transform: capitalize;
}
.testimonials .carousel .thumbs-wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    padding-top: 24px;
}
.testimonials .carousel .thumb{
    width: 60px!important;
    transition: all .5s;
    overflow: unset!important;
    position: relative;
}
.testimonials .carousel .thumb:after, .testimonials .carousel .thumb:before{
    content: "";
    background-color: var(--navy);
    border-radius: 50px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
}
.testimonials .carousel .thumb::before{
    height: 20px;
    width: 20px;
    top: -2px;
    border-radius: 0;
    rotate: 45deg;
    z-index: -1;
    left: 45%;
}
.testimonials .carousel .thumb::after{
    height: 7px;
    width: 7px;
    top: -23px;
}
.testimonials .carousel .thumb,.testimonials .carousel .thumb img{
    border-radius: 50px;
    background-color: var(--white);
}
.testimonials .carousel .thumb.selected,.testimonials .carousel .thumb:hover{
    border-color: var(--navy);
}
.testimonials .carousel .thumb.selected::after,.testimonials .carousel .thumb.selected::before,.testimonials .carousel .thumb.selected .shape-bx{
    opacity: 1;
}
.testimonials .carousel .thumb .shape-bx {
    position: relative;
    height: 35px;
    width: 50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 10px;
    z-index: 0;
    opacity: 0;
    transition: all .5s;
}
@media(max-width:767px){
    .testimonials .testimonial-groups{
        padding: 50px;
        background-size: 50px;
    }
    .testimonials .testimonial-groups .person-name{
        font-size: 20px;
    }
    .testimonials .testimonial-groups .designation{
        font-size: 13px;
    }
}
@media(max-width:575px){
    .testimonials .testimonial-groups{
        padding: 30px;
        background-size: 30px;
    }
    .testimonials .testimonial-groups .person-name{
        font-size: 18px;
    }
    .testimonials .testimonial-groups .designation{
        font-size: 12px;
    }
}