.footer{
    color: var(--white);
    font-size: 15px;
    background-image: url('../../../public/img/footerbg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-color: #896eff;
    padding: 70px 10% 30px;
}
.footer .content{
    color: var(--white);
}
.footer-top{
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px var(--white);
    padding-bottom: 20px;
}
.footer-top .logo img{
    max-width: 180px;
}
.footer-top .logo .heading{
    font-size: 40px;
    line-height: 0.8;
}
.footer-top .logo span{
    font-size: 13px;
    display: block;
    margin-left: 2px;
    font-weight: 600;
}
.footer-top .contact-info{
    display: flex;
}
.footer-top .contact-info .icon-wraper{
    display: flex;
    margin-right: 20px;
    max-width: 310px;
}
.footer-top .contact-info .icon-wraper .icon-content .content{
    font-size: 18px;
}
.footer-top .contact-info .icon-wraper .icon-lg{
    margin-right: 20px;
}
.footer-top .contact-info .icon-wraper .icon-lg img{
    min-width: 50px;
    max-width: 50px;
}
@media(max-width:1199px){  
    .footer-top .contact-info .icon-wraper .icon-content .content{
        font-size: 16px;
    }
    .footer-top .contact-info .icon-wraper .icon-lg img{
        min-width: 40px;
        max-width: 40px;
    }
}
@media(max-width:991px){  
    .footer-top{
        display: block;
    }
    .footer-top .logo{
        margin-bottom: 20px;
    }
    .footer-top .contact-info{
        display: block;        
    }
    .footer-top .contact-info .icon-wraper:last-child{
        margin-top: 20px;
    }
    .footer-top .logo .heading{
        font-size: 35px;
    }
    .footer-top .logo span{
        font-size: 11px;
    }
}
@media(max-width:840px){ 
    .footer-top .logo .heading{
        font-size: 30px;
    }
    .footer-top .logo span{
        font-size: 10px;
    }
}
@media(max-width:767px){ 
    .footer{
        padding-top: 40px;
        padding-bottom: 0;
    }
}
.footer-bottom{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.footer-bottom .footer-column{
    width: 23%;
    margin-bottom: 20px;
}
.footer-bottom .footer-column .footer-heading{
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 10px;
    margin: 0 0 30px;
    position: relative;
    color: #fff;
    line-height: 1.2;
    text-transform: capitalize;
}
.footer-bottom .footer-column .footer-heading:after, .footer-bottom .footer-column .footer-heading:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    border-radius: 20px;
    background-color: #fff;
}
.footer-bottom .footer-column .footer-heading:before {
    width: 15px;
}
.footer-bottom .footer-column .footer-heading::after{
    width: 45px;
    left: 20px;
}
.footer-bottom .bottom-content{
    font-size: 16px;
    margin-bottom: 15px;
}
.footer-bottom .socialLinks{
    display: flex;
}
.footer-bottom .socialLinks .icons{
    background-color: var(--white);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 7px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-bottom .socialLinks .icons:hover{
    background-image: linear-gradient(90deg,#896eff 0,#5f3bff 51%,#896eff);
}
.footer-bottom .socialLinks .icons img{
    width: 20px;
}
.footer-bottom .socialLinks .icons:hover .facebook{
    content: url('../../../public/img/facebook-white.png');
}
.footer-bottom .socialLinks .icons:hover .twitter{
    content: url('../../../public/img/twitter-white.png');
}
.footer-bottom .socialLinks .icons:hover .instagram{
    content: url('../../../public/img/instagram-white.png');
}
.footer-bottom .socialLinks .icons:hover .linkedin{
    content: url('../../../public/img/linkedin-white.png');
}
.footer-bottom .socialLinks .icons:hover .whatsapp{
    content: url('../../../public/img/whatsapp-white.png');
}
.footer-bottom .footer-list{
    display: flex;
    flex-direction: column;
}
.footer-bottom .footer-list .footerlinks{
    display: block;
    transition: all .5s;
    padding-left: 0;
}
.footer-bottom .footer-list .footerlinks:hover{
    padding-left: 10px;
}
@media(max-width:1199px){
    .footer-bottom .footer-column{
        width: 48%;
    }
    .footer-bottom .footer-column .footer-heading{
        font-size: 22px;
        margin-bottom: 20px;
    }
}
@media(max-width:575px){
    .footer-bottom .footer-column{
        width: 100%;
    }
    .footer-bottom .footer-column .footer-heading{
        font-size: 20px;
    }
    .bottom-content{
        font-size: 14px;
        margin-bottom: 10px;
    }
}