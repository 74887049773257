.serviceDetailPage{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.servicesDetailColumn1{
    width: 72%;
    max-width: calc(100% - 400px);
}
.servicesDetailColumn2{
    width: 25%;
    min-width: 380px;
}
.service-box{
    box-shadow: 0 0 10px rgb(0 5 39 / 10%);
    padding: 30px;
}
.serviceDetailPage .heading{
    font-size: 24px;
    margin: 20px 0;
    color: var(--black);
}
.serviceDetailPage .heading:first-child{
    margin-top: 0;
}
.serviceDetailPage .servicesDetailColumn2 .serviceCategory{
    display: block;
    padding: 8px 0;
    border-bottom: solid 1px var(--lightGray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--gray);
    font-size: 16px;
    font-weight: 700;
}
.serviceDetailPage .servicesDetailColumn2 .serviceCategory:first-child{
    border-top: solid 1px var(--lightGray);
}
.serviceDetailPage .servicesDetailColumn2 .serviceCategory:hover,.serviceDetailPage .servicesDetailColumn2 .serviceCategory.active{
    color: var(--purple);
}
.servicesDetailColumn1,.servicesDetailColumn2 .content{
    margin-bottom: 10px;
}
.serviceDetailPage .serviceImg{
    max-width: 100%;
    max-height: 500px;
    margin-bottom: 20px;
    display: block;
    margin: 0 auto 20px;
    animation: move2 3s linear infinite;
}
.serviceDetailPage .title{
    text-align: center;
}
.serviceDetailPage p ,.serviceDetailPage ul{
    margin-bottom: 20px;
}
@media(max-width:1199px){
    .servicesDetailColumn2{
        min-width: 300px;
    }
    .servicesDetailColumn1{
        max-width: calc(100% - 320px);
    }
}
@media(max-width:991px){
    .servicesDetailColumn1,.servicesDetailColumn2{
        width: 100%;
        max-width: 100%;
        min-width: auto;
    }
    .serviceDetailPage .heading{
        font-size: 22px;
    }
}
@media(max-width:767px){
    .serviceDetailPage .heading{
        font-size: 20px;
    }
    .serviceDetailPage .servicesDetailColumn2 .serciveCategory:hover{
        font-size: 14px;
    }
}
