.portfolio{
    margin: 40px 0 20px;
}
.portfolio .navbar{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    background-color: var(--purpleOpacity);
    padding: 15px;
    border-radius: 50px;
    width: 98%;
}
.portfolio .navlinks{
    background-color: transparent;
    color: var(--navy);
    border: none;
    margin-left: 10px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 15px;
}
.portfolio .navlinks:hover,.portfolio .navlinks.active{
    background-color: var(--purple);
    color: var(--white);
}
.portfolio .galleryContainer{
    display: flex;
    flex-wrap: wrap;
}
.galleryItem{    
	position: relative;
    overflow: hidden;
    margin-right: 2%;
    width: 23%;
    height: 250px;
    box-shadow: 0 0 5px var(--midGray);    
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: var(--white);
}
.galleryItem:hover{
    box-shadow: 0 0 5px var(--purple);
}
.galleryItem .galleryImage{    
	width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    transition: all 5s ease-in-out;
}
.galleryItem:hover .galleryImage{
    object-position: bottom center;
}
.galleryItem .galleryButtons{
    position: absolute;
    bottom: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: var(--purpleOpacity2);
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    transition: all .3s linear 0s;
}
.galleryItem:hover .galleryButtons{
    opacity: 1;
    visibility: visible;
}
.galleryIcons{
    background-color: var(--white);
    padding: 5px;
    width: 40px;
    height: 40px;
    line-height: 10px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 0 5px var(--purple);
    text-align: center;
    display: block;
    margin: auto;
    margin-top: 95px;
}
.galleryIcons:last-child{
    margin-right: 0;
}
.galleryIcons img{
    width: 18px;
}
.galleryTitle{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    color: var(--white);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    text-align: center;
}
.modalImage{
    width: 100%;
}
@media(max-width:1380px){
    .galleryItem{ 
        width: 31%;
    }
}
@media(max-width:991px){
    .galleryItem{ 
        width: 48%;
        margin-right: 2%;
    }
    .portfolio .navlinks{
        font-size: 16px;
    }
    .galleryTitle{
        font-size: 20px;
    }
}
@media(max-width:575px){
    .galleryItem{ 
        width: 100%;
        margin-right: 0;
    }
    .galleryItem{ 
        height: 300px;
    }
    .galleryItem:hover .galleryImage{
        object-position: top center;
    }
    .portfolio  .navlinks{
        font-size: 14px;
    }
    .galleryTitle{
        font-size: 18px;
    }
    .portfolio .navbar{
        width: 100%;
    }
}
@media(max-width:450px){
    .portfolio .galleryItem{ 
        height: 250px;
    }
}