.header{
    position: absolute;
    top: 30px;
    width: 100%;
    height: 50px;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    z-index: 1000;
    transition: all ease-in-out;
}
.header.active,.header.active.white{
    position: fixed;
    top: 0;
    background-color: var(--white);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
}
.header.white{
    background-color: transparent;
}
.header.white .logo{
    color: var(--white);
}
.header .logo,.header.active.white .logo{
    color: var(--navy);
}
.header .logo img{
    height: 40px;
}
.header .logo .heading{
    font-size: 35px;
    line-height: 0.8;
}
.header .logo span{
    font-size: 11px;
    display: block;
    margin-left: 2px;
    font-weight: 600;
}
.header .navlinks{
    font-size: 16px;
    font-weight: 700;
    margin-left: 25px;
    padding: 0 10px 14px;
    position: relative;
    color: var(--navy);
}
.header.white .navlinks{
    color: var(--white);
}
.header.white.active .navlinks{
    color: var(--navy);
}
.header .navlinks::after{
    display: block;
    content: '';
    width: 0%;
    height: 4px;
    background: var(--purple);
    position: absolute;
    left: 0;
    bottom: 0;
    transition: width 0.3s ease-in;
}
.header.white .navlinks::after{
    background: var(--white);
}
.header.white.active .navlinks::after{
    background: var(--purple);
}
.header .navlinks:hover::after,.header .navActive::after {
    width: 100%;
}
.header.menuButtonContainer,.header .menuToggle {
    display: none;
}
.talk-btn,.talk-btn:hover,.talk-btn:focus{
    margin-left: 30px;
    background-color:rgb(54 199 54);
    padding: 8px 15px;
    border-radius: 4px;
    color: var(--white);
    position: relative;
    font-size: 16px;
    font-weight: 700;
}
.talk-btn::before{
    content: '';
    width: 15px;
    height: 20px;
    position: absolute;
    left: -5px;
    bottom: 3px;
    background-color:rgb(54 199 54);
    z-index: -1;
    rotate: 30deg;
}
.talk-btn.talk-2{
    display: none;
}
@media(max-width:1199px){
    .talk-btn.talk-1{
        display: none;
    }
    .talk-btn.talk-2{
        display: inline-block;
        margin-left: 0;
    }
    .header .navbar{
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: var(--lightPurple);
    }
    .header .navlinks{
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: all 400ms ease-out;
        overflow: hidden;
    }
    .header .navlinks::after{
        display: none;
    }
    .header .navActive{
        border-color: var(--purple);
    }
    .header.menuButtonContainer {
        height: 100%;
        width: 24px;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .header .menuToggle {
        display: block;
        opacity: 0;
        position: absolute;
        right: 7%;
        width: 23px;
        height: 23px;
    }
    .header .menuButton::before {
        content: "";
        margin-top: -8px;
    }
    .header .menuButton::after {
        content: "";
        margin-top: 8px;
    }
    .header .menuToggle:checked + .menuButtonContainer .menuButton::before {
        margin-top: 0px;
        transform: rotate(405deg);
    }
    .header .menuToggle:checked + .menuButtonContainer .menuButton {
        background: rgba(255, 255, 255, 0);
    }
    .header .menuToggle:checked + .menuButtonContainer .menuButton::after {
        margin-top: 0px;
        transform: rotate(-405deg);
    }
    .header .menuToggle:checked ~ .navbar .navlinks {
        padding: 10px 20px;
        height: 40px;
        width: 100%;
        text-align: center;
        border-bottom: solid 2px var(--purple);
        transition: all 400ms ease-out;
    }
    
    .header .menuToggle:checked ~ .navbar .navActive{
        border-color: var(--purple);
    }
    .header .menuToggle:checked ~ .navbar .navlinks:last-child{
        border-bottom: none;
    }
    .header .menuButtonContainer{
        display: flex;
    }
    .header .menuButton,.header .menuButton::before,.header .menuButton::after {
        display: block;
        background-color: var(--purple);
        position: absolute;
        height: 4px;
        width: 24px;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 2px;
        cursor: pointer;
    }
    .header.white .menuButton,.header.white .menuButton::before,.header.white .menuButton::after{
        background-color: var(--white);
    }
    .header.white.active .menuButton,.header.white.active .menuButton::before,.header.white.active .menuButton::after{
        background-color: var(--purple);
    }
    .header.white .navlinks{
        color: var(--navy);
    }
}
@media(max-width:991px){
    .header .navlinks,.talk-btn,.talk-btn:hover,.talk-btn:focus{
        font-size: 14px;
    }
}
@media(max-width:840px){
    .header .logo img{
        height: 34px;
    }
    .header .logo .heading{
        font-size: 30px;
    }
    .header .logo span{
        font-size: 10px;
    }
}
@media(max-width:575px){
    .header .container{
      padding: 0 20px;
    }
    .header .menuToggle {
        opacity: 0;
        position: absolute;
        right:20px;
    }
}
